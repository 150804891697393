.genres {
    color: #B456FB;
    font-family: 'Axiforma';
    font-weight: 100;
    font-size: 1.5rem;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.5vh;
    height: 5vw;
}

.genre {
    font-size: 1.5vw;
    border: 1px solid #B456FB;
    border-radius: .2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(80% /5);
}

.genre:hover {
    color: white;
}

.genres .active {
    background-color: #B456FB !important;
    color: white !important;
}