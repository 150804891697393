.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
    margin-top: 4.5vh;
    margin-bottom: 1.5vh;
    border: .1vw solid white;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}