.header {
    /* background-color: #282c34; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vh;
  }
  
  .header #site-logo {
    height: 3vh;
  }
  
  .search-wrapper {
    border-radius: 25px;
    background-color: #DFB833;
    height: 5vh;
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .header #site-search {
    border: none;
    display: block;
    font-size: 1.2rem;
    padding: 1vh 2vw;
    width: 90%;
    height: 100%;
  }
  
  .header #site-search:focus {
    outline: none;
  }
  
  #search-icon {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 40%;
    width: 10%;
    overflow: hidden;
  }
  
  #search-icon-img {
    height: 100%;
  }